<template>
  <div class="px-15 w-full lg:px-7 sm-ws:px-10 flex flex-col mt-6">
    <div
      class="flex leading-2 text-promy-purple-400 text-promy-2xl font-extrabold font-main sm:text-lg xl:mb-12 lg:mb-4"
    >
      <span class="text-promy-gray-650">Partenaires:</span>
      <span class="text-promy-gray-625 ml-2 font-normal sm:text-xl">
        Secteurs d'activités
      </span>
    </div>
    <div
      class="flex sm:flex-col sm:items-start sm:space-y-3 items-center lg:text-base mt-6 xl:text-lg font-main font-extrabold justify-between border-b-2 xl:pb-5 lg:pb-4 xl:border-promy-gray-300"
    >
      <span class="text-promy-gray-650 font-extrabold">
        Liste des secteurs d'activités
      </span>
      <span
        v-if="$can('edite,partenaire')"
        class="green-text-btn"
        @click="toggleAddForm"
      >
        <span class="">Ajouter un secteur </span>
        <i class="fa fa-plus pl-2 text-sm"></i>
      </span>
    </div>

    <!-- add form -->
    <div
      class="lg:relative bg-white my-4 rounded-box px-6 py-7 sm:p-4 shadow-xs border-none hover:shadow-lg transition-shadow duration-200 procard"
      v-show="showAddForm"
    >
      <div class="sm:flex sm:flex-col grid gap-4 items-center grid-cols-2">
        <div class="flex justify-start sm:w-full">
          <span class="flex flex-col w-full">
            <input-text
              class="w-full"
              :rules="'required'"
              placeholder="Secteur d'activité"
              v-model="sector"
              type="text"
              name="sector"
            />
            <span class="msg-error" style="display: none"></span>
          </span>
        </div>
        <div class="flex justify-end items-start">
          <button
            class="bg-promy-green-300 rounded-full py-2 px-4 font-extrabold text-white mr-3 cursor-pointer disabled:opacity-50 capitalize"
            @click="storeSector"
            :disabled="sector == null"
          >
            ajouter
          </button>
        </div>
      </div>
    </div>

    <div v-if="!isLoading && sectors.length" @click="resetEdit">
      <div
        class="lg:relative bg-white my-4 rounded-box px-6 py-7 sm:p-4 shadow-xs border-none hover:shadow-lg transition-shadow duration-200 procard"
        v-for="sector_item in sectors"
        :key="sector_item.id"
      >
        <div class="sm:flex sm:flex-col grid gap-4 items-center grid-cols-2">
          <div
            class="flex justify-start sm:w-full"
            @click="stopEventPropagationInput"
          >
            <span
              class="text-gray-700 text-base capitalize font-semibold"
              v-if="!editMode || editedItem != sector_item.id"
            >
              {{ sector_item.name }}
            </span>
            <span
              class="flex flex-col w-full"
              v-if="editMode && editedItem === sector_item.id"
            >
              <input-text
                class="w-full sm:w-9/10"
                :rules="'required'"
                placeholder="Secteur"
                v-model="sector"
                type="text"
                name="sector"
              />
              <span class="msg-error" style="display: none"></span>
            </span>
          </div>
          <div class="flex justify-end sm:absolute sm:top-5 sm:right-4">
            <button
              @click="showActions(sector_item.id)"
              class="hidden sm:block w-2 h-5 border-l-4 border-dotted border-promy-green-300"
            />
            <template v-if="$can('edite,partenaire')">
              <div
                v-on-clickaway="away"
                v-if="show_actions_id === sector_item.id || $mq != 'sm'"
                class="flex space-x-2 bg-white sm:px-3 sm:py-2 rounded-lg sm:absolute sm:-top-12 sm:right-3"
              >
                <div
                  v-if="!editMode || editedItem != sector_item.id"
                  @click="editItem($event, sector_item)"
                  class="green-icon-btn"
                >
                  <i class="fas fa-edit" />
                </div>
                <div
                  v-if="editMode && editedItem === sector_item.id"
                  class="white-icon-btn"
                  @click="updateSector($event)"
                >
                  <i class="fas fa-edit" />
                </div>
                <div
                  class="green-icon-btn"
                  @click="displayModal(sector_item.id)"
                >
                  <i class="fas fa-trash" />
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div
        v-observe-visibility="{
          callback: handleInfiniteScroll,
        }"
      ></div>
    </div>
    <div v-else-if="isLoading">
      <div
        class="lg:text-base mt-10 xl:text-lg font-main font-extrabold xl:pb-5 lg:pb-4 text-center"
      >
        <span class="text-promy-gray-650 font-extrabold">
          Chargement en cours...
        </span>
      </div>
    </div>
    <div v-else>
      <div
        class="lg:text-base mt-10 xl:text-lg font-main font-extrabold xl:pb-5 lg:pb-4 text-center"
      >
        <span class="text-promy-gray-650 font-extrabold">
          La liste des secteurs est vide
        </span>
      </div>
    </div>

    <ValidateModal
      v-if="showModal"
      @isCancel="showModal = false"
      @isValide="deleteSector()"
    >
      <slot slot="message_body">
        Êtes-vous sûr de vouloir supprimer ce secteur ?
      </slot>
      <slot slot="button_valide">Continuer</slot>
      <slot slot="button_cancel">Annuler</slot>
    </ValidateModal>
  </div>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway'

export default {
  directives: {
    onClickaway: onClickaway,
  },

  data() {
    return {
      showModal: false,
      sector: '',
      sectors: [],
      isLoading: false,
      showAddForm: false,
      editMode: false,
      editedItem: 0,
      id_to_be_deleted: null,
      current_page: 1,
      last_page: null,
      show_actions_id: -1,
    }
  },
  created() {
    this.getSectors()
  },
  methods: {
    showActions(id) {
      this.show_actions_id = id
    },
    away() {
      this.show_actions_id = -1
    },
    getSectors() {
      this.isLoading = true
      this.$http
        .get(`/admin/activity_sector?page=${this.current_page}`)
        .then(({ data }) => {
          this.sectors =
            this.current_page > 1 ? [...this.sectors, ...data.data] : data.data
          this.last_page = data.last_page
          this.isLoading = false
        })
    },
    storeSector() {
      this.$http
        .post('/admin/activity_sector', { name: this.sector })
        .then(({ data }) => {
          this.sectors.unshift(data)
          this.sector = null
          this.showAddForm = false
          this.$toaster.success('Le secteur a été ajouté avec succès')
        })
        .catch((err) => {
          if (err.response.status === 422) {
            this.$toaster.error('Le nom du secteur existe déjà')
          }
        })
    },
    deleteSector() {
      this.$http
        .delete(`admin/activity_sector/${this.id_to_be_deleted}`)
        .then((response) => {
          this.sectors = [
            ...this.sectors.filter(
              (sector) => sector.id != this.id_to_be_deleted,
            ),
          ]
          this.showModal = false
          this.id_to_be_deleted = null
          this.$toaster.warning('Le secteur a été supprimé avec succès')
        })
        .catch(
          ({
            response: {
              data: { error },
            },
          }) => {
            this.showModal = false
            this.id_to_be_deleted = null
            this.$toaster.error(error)
          },
        )
    },
    displayModal(id) {
      this.showModal = true
      this.id_to_be_deleted = id
    },
    updateSector(event) {
      event.stopPropagation()
      if (this.sector.length === 0) {
        this.$toaster.error('le champ secteur ne peut pas être vide')
        return
      }
      this.$http
        .put(`admin/activity_sector/${this.editedItem}`, {
          name: this.sector,
        })
        .then(({ data }) => {
          let updatedItemIndex = this.sectors.findIndex(
            (sector) => sector.id === this.editedItem,
          )
          this.sectors[updatedItemIndex].name = data.name
          this.sectors[updatedItemIndex].updated_at = data.updated_at
          this.editMode = false
          this.sector = null
          this.editedItem = 0
          this.$toaster.info('Le secteur a été mis à jour avec succès')
        })
        .catch((err) => {
          if (err.response.status === 422) {
            this.$toaster.error('Le nom du secteur existe déjà')
          }
        })
    },
    toggleAddForm() {
      this.sector = null
      this.editMode = false
      this.showAddForm = !this.showAddForm
    },
    editItem(event, sector) {
      event.stopPropagation()
      if (this.showAddForm) this.showAddForm = !this.showAddForm
      this.editMode = true
      this.editedItem = sector.id
      this.sector = sector.name
    },
    resetEdit() {
      this.editMode = false
    },
    stopEventPropagationInput(event) {
      event.stopPropagation()
    },
    handleInfiniteScroll(isVisible) {
      if (!isVisible || this.last_page === this.current_page) {
        return
      }
      this.current_page++
      this.getSectors()
    },
  },
}
</script>
